import ModuleLoaderService, { ModuleType, type ModuleGroupsLoadingConfig } from '@ts/services/module-loader-service';
import InitializerService from '@ts/services/initializers-service';
import { Global } from './globals.d';

const loadDefaultJSModules = async () => {
    const SERVICE_MODULES: ModuleGroupsLoadingConfig = {
        moduleType: ModuleType.SERVICE,
        moduleGroups: [['alpine-init-service', 'back-to-top-button-init-service', 'unique-headline-id-check', 'switch-button-helper']],
    };

    await ModuleLoaderService.loadJSModules(SERVICE_MODULES);
};

const loadOptionalJSModules = async () => {
    const OPTIONAL_SERVICE_MODULES: ModuleGroupsLoadingConfig = {
        moduleType: ModuleType.SERVICE,
        moduleGroups: [[]],
    };
    const OPTIONAL_COMPONENT_MODULES: ModuleGroupsLoadingConfig = {
        moduleType: ModuleType.COMPONENT,
        moduleGroups: [[]],
    };

    if (document.querySelectorAll(Global.FigureTableSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['table-parser-service']);  
    }
    if (document.querySelectorAll(Global.DataInfoMessageSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['info-message-popup-service']);
    }
    if (document.querySelectorAll(Global.ErrorMessageSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['error-message-service']);
    }
    if (document.querySelectorAll(Global.VideoPlayerSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['video-service']);
    }
    if (document.querySelectorAll(Global.CheckboxGroupSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['checkbox-group-service']);
    }
    if (document.querySelectorAll(Global.ExternalLinkSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['external-links-service']);
    }
    if (document.querySelectorAll(Global.DataWoerterbuchSelector).length > 0) {
        OPTIONAL_SERVICE_MODULES.moduleGroups.push(['woerterbuch-popup-service']);
    }

    if (OPTIONAL_SERVICE_MODULES.moduleGroups.length > 0) {
        await ModuleLoaderService.loadJSModules(OPTIONAL_SERVICE_MODULES);
    }
    if (OPTIONAL_COMPONENT_MODULES.moduleGroups.length > 0) {
        await ModuleLoaderService.loadJSModules(OPTIONAL_COMPONENT_MODULES);
    }
};

const handleAllInitializersFinished = () => {
    Alpine && Alpine.start();
};

const main = async () => {
    await loadDefaultJSModules();
    await loadOptionalJSModules();

    InitializerService.checkInitializers(handleAllInitializersFinished);
};

main();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// @ts-ignore
if (import.meta.hot) {
    // @ts-ignore
    import.meta.hot.accept(() => {});
}
